import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersMobilebattery extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobilebattery/header-mb_sp.jpg" />
	 		<Image src="/img/mobilebattery/header-mb.jpg" alt="オンラインストア限定" fluid />
	 	</picture>
	
	 </div>
	 <picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image src="/img/android/ac-caution.png" alt="ご注意" fluid />
	</picture>
	 <div className="mb-5 pb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobileitem/period_sp.jpg" />
	 		<Image src="/img/mobileitem/period.jpg" alt="期間" fluid />
	 	</picture>
	
	</div>
	 <Row className="mt-5">
	 <Col xs={12} sm={6}  className=""><a href="#wireless" className="btn-outline-dark w-100 btn btn-smp btn-lg font_l py-3"><i className="fas fa-bolt"></i> ワイヤレス充電器</a></Col>
<Col xs={12} sm={6} className=""><a href="#mobilebattery" className="btn-outline-dark w-100 btn btn-smp btn-lg font_l py-3"><i className="fas fa-bolt"></i> モバイルバッテリー</a></Col>
</Row>

		
		 
{/* 	<picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution2_sp.png" />
		<Image className="mb-5" src="/img/android/ac-caution2.png" alt="ご注意" fluid />
	</picture>*/}
	 </Container>
	 <Container fluid={true} className="bg_bl py-5 my-5">
	 <Container>
	 <div id="wireless" className="mt-5 pb-5">
	 <div className="my-5 pb-5">
	 <picture>
	<source media="(max-width: 767px)" srcSet="/img/mobilebattery/ttl_wc_sp.png" />
		 <Image className="mt-5" src="/img/mobilebattery/ttl_wc.png" alt="" fluid />
	 </picture>
	 </div>
	 <div className="my-5">
	<picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobilebattery/wc_spec_sp.jpg" />
	 		<Image src="/img/mobilebattery/wc_spec.jpg" alt="" fluid />
	 	</picture>
		 </div>	

		<picture>
			<source media="(max-width: 767px)" srcSet="/img/mobilebattery/ttl_item_sp.png" />
		 <Image className="mt-5" src="/img/mobilebattery/ttl_item.png" alt="一覧" fluid />
	 </picture>
	 <p className="text-right pt-5">各種 ￥<span className="font_ll">4,378</span> (税込)</p>
	</div>
	<div className="mb_list mb-5 text-center">
	<Row>
		<Col xs={6} sm={4} ><a href="/item/Qi_011/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/Qi_011_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_021/">ごろごろにゃんすけ</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/Qi_012/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/Qi_012_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_022/">しばんばん</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/Qi_013/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/Qi_013_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_023/">ぴよこ豆</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/Qi_014/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/Qi_014_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_024/">ばいばいべあ</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/Qi_015/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/Qi_015_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_025/">うさぎのムーちゃん</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/Qi_016/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/Qi_016_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_026/">ツンダちゃん</a></p></Col>
		
 </Row>
 </div>

	 </Container></Container>

	 <Container>
	 <div id="mobilebattery" className="my-5 pt-5">
	 <picture>
	<source media="(max-width: 767px)" srcSet="/img/mobilebattery/ttl_mb_sp.png" />
		 <Image className="mt-5" src="/img/mobilebattery/ttl_mb.png" alt="" fluid />
	 </picture>
	 <div className="my-5 pb-5">
	<picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobilebattery/mb_spec01_sp.jpg" />
	 		<Image src="/img/mobilebattery/mb_spec01.jpg" alt="mobilebattery" fluid />
	 	</picture>
		 </div>	
		<picture>
		<picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobilebattery/mb_spec02_sp.jpg" />
	 		<Image src="/img/mobilebattery/mb_spec02.jpg" alt="mobilebattery" fluid />
	 	</picture>
	<source media="(max-width: 767px)" srcSet="/img/mobilebattery/ttl_item_sp.png" />
		 <Image className="mt-5" src="/img/mobilebattery/ttl_item.png" alt="一覧" fluid />
	 </picture>
	 <p className="text-right pt-5">各種 ￥<span className="font_ll">4,378</span> (税込)</p>
	</div>
	<div className="mb_list mb-5 pb-5 text-center">
	<Row>
		<Col xs={6} sm={3} ><a href="/item/mb_021/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_021_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_021/">ごろごろにゃんすけ</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_022/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_022_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_022/">しばんばん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_023/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_023_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_023/">ぴよこ豆</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_024/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_024_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_024/">ばいばいべあ</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_025/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_025_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_025/">うさぎのムーちゃん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_026/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_026_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_026/">ツンダちゃん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_027/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_027_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_027/">BABY ごろごろにゃんすけ</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_028/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_028_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_028/">BABY しばんばん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_029/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_029_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_029/">BABY ぴよこ豆</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_030/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_030_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_030/">BABY ばいばいべあ</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_031/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_031_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_031/">BABY うさぎのムーちゃん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_032/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_032_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_032/">BABY ツンダちゃん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_033/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_033_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_033/">ごろごろにゃんすけ ホイップぼうや</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_034/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_034_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_034/">ごろごろにゃんすけ ぴえん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_035/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_035_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_035/">ごろごろにゃんすけ あたまお花畑ちゃん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_036/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_036_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_036/">ごろごろにゃんすけ そそくささん</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_037/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_037_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_037/">ごろごろにゃんすけ どんぐりぼうや</a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/mb_038/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/mb_038_main.jpg" alt="" fluid /></a>
		<p><a href="/item/mb_038/">ごろごろにゃんすけ いちごちゃん</a></p></Col>
 </Row>
 </div>
 {/*<Row>
	 		<Col xs={12} sm={{ span: 4, offset: 4 }} ><a href="/category/アイテムから探す-限定商品(モバイル)-モバイルバッテリー" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> アイテム一覧</a></Col>
	 	</Row>*/}
	 </Container>


{/* */}

<Container className="mb-4 ">

	 <div className="oshirase">
	 <Row>
	 <Col xs={12}>
	 <h3><i className="fas fa-exclamation-triangle"></i> 使用上のご注意 <i className="fas fa-exclamation-triangle"></i></h3>
	 <ul className="mb-5">
	<li>※本来の用途以外には使用しないでください。</li>
	<li>※ご使用の際には取扱説明書をよくお読みの上、正しくお使いください。</li>
	<li>※本製品のご使用中に伴う、接続機器の故障、内部データの破損に関しては一切の責任を負いかねますので、ご了承ください。</li>
	</ul></Col></Row>
	 </div>
	 
	
</Container>
<Container className="my-5">
<a href="/others/glasscase/" className="opa06 mb-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/glasscase/bnr_glasscase_l_sp.jpg" />
	 <Image src="/img/glasscase/bnr_glasscase_l.jpg" alt="オンラインストア限定ケース" fluid className="mb-5"/>
 </picture></a>
 <div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersMobilebattery);
