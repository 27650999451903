import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

import GetItemInfo from '../GetItemInfo';
import './bine.css';

class OthersBine extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	
	render() {
		return (
							<div>
							<Header/>
			<main className={this.state.cognitoid ? 'others chara-lp members bine' : 'others chara-lp'}>


<Container>
	 <Row>
		 	  <div className="top-img">
	   <h2><picture className="mb-5"><source media="(max-width: 767px)" srcSet="/img/bine/bine_top_sp.jpg" />
		 <Image src="/img/bine/bine_top.jpg" alt="Bine" fluid />
	 </picture>
	 	</h2>
	 </div>
		 </Row>

</Container>
<Container>
<div className="point">
	 <h3>
	 <Image src="/img/bine/point.png" alt="POINT" />
	 </h3>
	 <picture><source media="(max-width: 767px)" srcSet="/img/bine/bine_img01_sp.jpg" />
		 <Image src="/img/bine/bine_img01.jpg" alt="Bine" className="my-5 py-5" fluid />
	 </picture>

</div>
<div className="lineup mb-5">
	 <h3>
	 <Image src="/img/bine/lineup.png" alt="LINE UP" />
	 </h3>

	 <div className="pucate-item my-5 py-5">
<Row>
<Col xs={12} sm={3} ><a href="/item/57955/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57955_main.jpg" alt="" fluid /></a>
<p><a href="/item/57955/"><GetItemInfo code="57955" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} ><a href="/item/57956/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57956_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57956/"><GetItemInfo code="57956" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} ><a href="/item/57957/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57957_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57957/"><GetItemInfo code="57957" setkey="name" /></a></p></Col>{/* */}
 <Col xs={12} sm={3} ><a href="/item/57958/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57958_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57958/"><GetItemInfo code="57958" setkey="name" /></a></p></Col>

</Row>
</div>
</div>
	 </Container>
<Container fluid={true} className="bg_iv py-5 my-5">
<Container>
<picture><source media="(max-width: 767px)" srcSet="/img/bine/bine_img02_sp.jpg" />
		 <Image src="/img/bine/bine_img02.jpg" alt="Bine" className="mt-5 pt-5" fluid />
	 </picture>
	 <div className="lineup mb-5">
	 <h3>
	 <Image src="/img/bine/lineup.png" alt="LINE UP" />
	 </h3>
<div className="pucate-item my-5 py-5">
<Row>
 <Col xs={6} sm={3} ><a href="/item/57960/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57960_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57960/"><GetItemInfo code="57960" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57961/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57961_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57961/"><GetItemInfo code="57961" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57962/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57962_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57962/"><GetItemInfo code="57962" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57963/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57963_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57963/"><GetItemInfo code="57963" setkey="name" /></a></p></Col>
</Row>
</div></div>
</Container>
</Container>

<Container>
 <div className="text-right mt-5"><a href="../../stationary_index/">トップページに戻る  <i className="fas fa-arrow-right"></i></a></div>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersBine);
