import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class CompanyMain extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
			<Container>
				<div className="head-logo sec">
					<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
				</div>

					<div className="pan d-flex justify-content-end">
						<div><a href="/stationary_index/">TOP</a></div>
						<div><a href={'/company/'}>会社概要</a></div>
						<div>特定商取引に基づく表示</div>

					</div>
				<h1>特定商取引に基づく表示</h1>
</Container>


<Container className="longtxt">
	 <Row>
            		 <Col lg={{ span: 10, offset: 1 }} md={12}>
		<div className="company">
				<dl>
					<dt>販売業者名</dt>
					<dd>株式会社クヌム</dd>
					<dt>所在地</dt>
					<dd>〒553-0004　大阪府大阪市福島区玉川4丁目15-11<br />
					タマガワファーストビル　5階</dd>
					<dt>電話番号</dt>
					<dd>06-7220-4222</dd>
					<dt>代表取締役</dt>
					<dd>米村　栄一</dd>
					<dt>販売価格</dt>
					<dd>商品毎に記載</dd>
					<dt>販売価格以外に必要な費用</dt>
					<dd>消費税／送料／ギフトラッピング費用／決済手数料</dd>
					<dt>支払方法及び支払時期</dt>
					<dd><h4>クレジットカードでのお支払い</h4>
<p>ご利用いただけるカードは国内発行かつご本人様名義のVISA、MasterCard、JCB、AMEX、Dinersです。支払時期は各カード会社の引き落とし日です。</p>

<h4>GMO後払い決済でのお支払い</h4>
<p>商品出荷後、商品とは別に払込票を郵送いたしますので、お近くのコンビニエンスストア・郵便局（ゆうちょ銀行）・銀行にて払込票に記載の期日内にお支払いいただく方法です。<br />
決済手数料はお客様負担となります。</p>

<h4>代金引換でのお支払い</h4>
<p>お届けの際にヤマト運輸または郵便局の配達員に商品と引き換えにお支払いいただく方法です。<br />
代引き手数料はお客様負担となります。</p>
						<p>詳しくは「<a href="../../guide/payment/">お支払いについて</a>」をご確認ください。</p></dd>
						<dt>引渡時期、発送方法</dt>
						<dd>お客様がご注文を確定した後、5日以内に（お届け日を指定した場合は指定日にお届けできるように）倉庫より発送いたします。なお、ご注文をお受けできない場合は、その旨のご連絡をいたします。
詳しくは「<a href="../../guide/send/">配送・送料について</a>」をご確認ください。</dd>
			<dt>返品、不良品の交換について</dt>
			<dd>不良・破損（ゆうパケット配送分を除く）商品の返品・交換は、商品到着後8日以内にご連絡ください。在庫がある場合は交換、ない場合は返品を承ります。<br />
			お客様都合（注文間違い、イメージと違う等）の返品・交換は承っておりません。
詳しくは「<a href="../../guide/#returns">返品・交換について</a>」をご確認ください。</dd>
			<dt>販売数量の制限</dt>
			<dd>数量限定や期間限定の商品等については、商品ページに記載いたします。</dd>
				</dl>
	</div>
		</Col>
			</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(CompanyMain);
